import "../pages/on-board.css"
const BlogCard = ({ blog }) => {
    return (
        <div className="flex flex-col rounded glass_morphism hover:scale-95 hover:cursor-pointer transition-all duration-300 delay-300 hover:backdrop-blur-lg gap-y-4">
            <img src={blog?.imgUrl} className="max-w-full max-h-[13rem] rounded" alt="blog_img" />
            <div className="font-sans text-white  px-2 leading-6">
                {blog?.mainContent}
            </div>
        </div>

    )

}
export default BlogCard;