import { Input, InputWrapper } from "@mantine/core";
import { useSelector } from "react-redux";

const ProfilePage = () => {
    const userInfo = useSelector((state) => state?.user?.user?.user);

    return (
        <div className="wfull flex flex-col justify-center items-center">
            <div className="w-1/2 h-full flex p-4 flex-col items-center">
                <img
                    className=" rounded-full w-40"
                    src={`https://ui-avatars.com/api/?name=${userInfo?.email}&bold=true`}
                    alt=""
                />
            </div>
            <div className=" w-1/2 flex flex-col items-center mt-5 mx-auto max-w-[800px]">
                <div className="grid w-full gap-6 sm:grid-cols-2">

                    <InputWrapper description="Email Address">
                        <Input style={{ color: 'white' }} value={userInfo?.email} disabled type={"text"} />
                    </InputWrapper>
                    <InputWrapper description="Email Verified">
                        <Input style={{ color: 'white' }} value={userInfo?.emailVerified ? 'Verified' : 'Not Verified'} disabled type={"text"} />
                    </InputWrapper>



                </div>
            </div>
        </div>

    )
}
export default ProfilePage;