import React, { useState, useEffect } from 'react';
// import './trade.css'
import withAuth from '../ProtectionHOCs/withAuth';
import "./on-board.css"
import { useDispatch, useSelector } from 'react-redux';
import { connectExchanger, disconnectExchanger, handleApiKeyChanges } from '../../redux/slice/userSlice';

function Trade() {

  const dispatch = useDispatch();

  const [accountInfo, setAccountInfo] = useState(null);
  const [botRunning, setBotRunning] = useState(false);
  const [apiKey, setApiKey] = useState('');
  const [apiSecretKey, setApiSecretKey] = useState('');
  const [exchangeType, setExchangeType] = useState('binanceFutures');
  const [connected, setConnected] = useState(false);
  const [accountId, setAccountId] = useState(null);
  const [tradeDecision, setTradeDecision] = useState(null);
  const [futuresAssets, setFuturesAssets] = useState([]);
  const [tradingPairs, setTradingPairs] = useState([]);
  const [orderType, setOrderType] = useState('market');
  const [selectedPair, setSelectedPair] = useState('KSMUSDT');
  const [showAccountInfo, setShowAccountInfo] = useState(false);
  const [orderTypes, setOrderTypes] = useState(['market', 'limit', 'trailing']);
  const [tradeResult, setTradeResult] = useState(null);
  const [tradeResultVisible, setTradeResultVisible] = useState(false);
  const [symbol, setSymbol] = useState('');
  const accId = useSelector((state) => state.user.accountId);
  const apiSecKey = useSelector((state) => state.user.apiSecretKey);
  const api_Key = useSelector((state) => state.user.apiKey);


  useEffect(() => {
    const fetchAccountInfo = async () => {
      try {
        const response = await fetch(process.env.NODE_ENV == 'production' ? 'https://freedombot.online/api/usdt-balance' : 'http://localhost:8001/api/usdt-balance', {
          headers: {
            'X-API-KEY': api_Key,
            'X-API-SECRET-KEY': apiSecKey
          }
        });
        if (!response.ok) {
          throw new Error('Error fetching account info');
        }
        const data = await response.json();
        setAccountInfo(data.usdtBalance);
      } catch (error) {
        console.error('Error fetching account info:', error);
        setAccountInfo(null);
      }
    };

    const fetchTradeDecision = async () => {
      try {
        const response = await fetch(process.env.NODE_ENV == 'production' ? `https://freedombot.online/api/trade-decision?symbol=${selectedPair}` : `http://localhost:8001/api/trade-decision?symbol=${selectedPair}`);
        if (!response.ok) {
          throw new Error('Failed to fetch trading decision');
        }
        const { decision } = await response.json();
        setTradeDecision(decision);
      } catch (error) {
        console.error('Error fetching trade decision:', error);
        setTradeDecision(null);
      }
    };

    const fetchTradingPairs = async () => {
      try {
        const response = await fetch('https://fapi.binance.com/fapi/v1/exchangeInfo');
        if (!response.ok) {
          throw new Error('Failed to fetch trading pairs');
        }
        const data = await response.json();
        const pairs = data.symbols.map(symbol => symbol.symbol);
        setTradingPairs(pairs);
      } catch (error) {
        console.error('Error fetching trading pairs:', error);
        setTradingPairs([]);
      }
    };

    const fetchHistoricalData = async () => {
      try {
        const response = await fetch(process.env.NODE_ENV == 'production' ? `https://freedombot.online/api/historical-data/${selectedPair}` : `http://localhost:8001/api/historical-data/${selectedPair}`);
        if (!response.ok) {
          throw new Error('Failed to fetch historical data');
        }
        const data = await response.json();
      } catch (error) {
        console.error('Error fetching historical data:', error);
      }
    };

    if (botRunning && connected) {
      fetchAccountInfo();
      fetchTradeDecision();
      fetchTradingPairs();
      fetchHistoricalData();
      const intervalId = setInterval(() => {
        fetchAccountInfo();
        fetchTradeDecision();
        fetchTradingPairs();
        fetchHistoricalData();
      }, 10000);
      return () => clearInterval(intervalId);
    }
  }, [botRunning, connected, apiKey, apiSecretKey, selectedPair]);

  useEffect(() => {
    if (tradeDecision && botRunning && connected && symbol) {
      executeTrade();
    }
  }, [tradeDecision, botRunning, connected, symbol]);

  const startBot = () => {
    setBotRunning(true);
  };

  const stopBot = () => {
    setBotRunning(false);
    setAccountInfo(null);
  };

  const connectExchange = async () => {
    dispatch(handleApiKeyChanges({ apiKey: !api_Key?.toString().trim() ? apiKey : api_Key, apiSecretKey: !apiSecKey?.toString().trim() ? apiSecretKey : apiSecKey }))
    try {
      const response = await fetch(process.env.NODE_ENV == 'production' ? 'https://freedombot.online/api/set-api-keys' : 'http://localhost:8001/api/set-api-keys', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          exchangeType: exchangeType === 'binanceFutures' ? 'binancefutures' : 'binance',
          apiKey: !api_Key?.trim() ? apiKey : api_Key,
          apiSecretKey: !apiSecKey.trim() ? apiSecretKey : apiSecKey
        })
      });
      if (!response.ok) {
        throw new Error('Failed to connect to exchange');
      }
      const data = await response.json();

      setConnected(true);
      dispatch(connectExchanger({ accountId: data.accountId }))
      setAccountId(data.accountId);
    } catch (error) {
      console.error('Error connecting to exchange:', error);
      setConnected(false);
      setAccountId(null);
    }
  };

  const disconnectExchange = () => {
    dispatch(disconnectExchanger())
    setConnected(false);
    setAccountId(null);
    setAccountInfo(null);
  };

  const executeTrade = async () => {
    try {
      const response = await fetch(process.env.NODE_ENV == 'production' ? 'https://freedombot.online/api/execute-trade' : 'http://localhost:8001/api/execute-trade', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          apiKey: !api_Key.toString().trim() ? apiKey : api_Key,
          symbol,
          apiSecretKey: !apiSecKey?.toString().trim() ? apiSecretKey : apiSecKey,
          tradeDecision,
          orderType,
          selectedPair
        })
      });
      const data = await response.json();
      if (!response.ok || !data.success) {
        console.log(response)
        throw new Error(response);
      }
      console.log('Trade executed successfully:', data.tradeResult);
      setTradeResult(data.tradeResult);
    } catch (error) {
      console.error('Error executing trade:', error);
      setTradeResult(null);
    }
  };

  useEffect(() => {
    if (tradeResult) {
      setTradeResultVisible(true);
      console.log('Trade Result:', tradeResult);
      alert(`Trade executed: ${JSON.stringify(tradeResult)}`);
    }
  }, [tradeResult]);

  return (
    <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-3">
      <div className='glass_morphism p-4'>
        <p className="text-white flex justify-center  font-semibold">FreeDom</p>
        <div className='flex flex-col gap-y-3'>
          <div className="flex flex-col gap-y-1">
            <p className="text-white text-sm font-normal text-start">API Key</p>
            <input type="text" value={!api_Key.toString().trim() ? apiKey : api_Key} onChange={(e) => {
              setApiKey(e.target.value)
            }} placeholder="Enter API Key" className="text-white border border-white p-1 px-4 outline-none bg-transparent rounded-md placeholder:text-white text-sm" />
          </div>
          <div className="flex flex-col gap-y-1">
            <p className="text-white text-sm font-normal text-start">API Secret Key</p>
            <input type="password" value={!apiSecKey?.toString().trim() ? apiSecretKey : apiSecKey} onChange={(e) => {
              setApiSecretKey(e.target.value)
            }} placeholder="Enter API Secret Key" className="text-white border border-white p-1 px-4 outline-none bg-transparent rounded-md placeholder:text-white text-sm" />
          </div>
          <div className="flex flex-col gap-y-1">
            <p className="text-white text-sm font-normal text-start" >Exchange Type:</p>
            <select className="text-white border border-white p-1 px-4 outline-none bg-transparent rounded-md placeholder:text-black text-sm" value={exchangeType} onChange={(e) => setExchangeType(e.target.value)}>
              <option className='bg-transparent text-black' value="spot">Binance Spot Trading</option>
              <option className='bg-transparent text-black' value="binanceFutures">Binance Futures Trading</option>
            </select>

          </div>

          {!connected ? (
            <button onClick={connectExchange} className='bg-white text-major-text-style w-[95%] md:w-[50%] mx-auto  rounded-xl p-2'>Connect Exchange</button>
          ) : (
            <>
              <button onClick={disconnectExchange} className='bg-white text-red-500 w-[95%] md:w-[50%] rounded-xl p-2 mx-auto'>Disconnect Exchange</button>
            </>
          )}

          <div className='flex flex-col gap-y-1'>
            <p className='text-white  font-medium '>Bot Control</p>
            <div className='w-full flex flex-col md:flex-row justify-between gap-x-8 gap-y-2'>
              {!botRunning ? (
                <button className='bg-white text-major-text-style w-[95%] md:w-[50%] rounded-xl p-2' onClick={startBot}>Start Bot</button>
              ) : (
                <>
                  <button onClick={stopBot} className='bg-white text-red-500 w-[95%] md:w-[50%] rounded-xl p-2'>Stop Bot</button>
                </>
              )}
              <button className='bg-white text-major-text-style w-[95%] md:w-[50%] rounded-xl p-2' onClick={() => setShowAccountInfo(!showAccountInfo)}>
                {showAccountInfo ? 'Hide Account Info' : 'Show Account Info'}
              </button>

            </div>
            {tradeDecision && (
              <p className='text-green-500'>Trade Decision: {tradeDecision}</p>
            )}
            {tradeResultVisible && (
              <div className="flex flex-col">
                <p className='text-white font-medium'>Trade Result</p>
                <p className='text-white'>{JSON.stringify(tradeResult)}</p>
              </div>
            )}
          </div>
          {accId && <p className='text-green-500'>Connected to Binance (Account ID: {accId})</p>}
          {!accId && <p>Connected to Binance</p>}
        </div>

      </div>


      <div className='glass_morphism p-4'>
        <p className="text-white flex justify-center  font-semibold">Trading Options</p>
        <div className='flex flex-col gap-y-3'>
          <div className="flex flex-col gap-y-1">
            <p className="text-white text-sm font-normal text-start">Trading Pair</p>
            <select className="text-white border border-white p-1 px-4 outline-none bg-transparent rounded-md placeholder:text-black text-sm" value={selectedPair} onChange={(e) => {
              setSelectedPair(e.target.value);
              setSymbol(e.target.value); // Update the symbol state
            }}>
              {tradingPairs.map(pair => (
                <option className='bg-transparent text-black' key={pair} value={pair}>{pair}</option>
              ))}
            </select>
          </div>
          <div className="flex flex-col gap-y-1">
            <p className="text-white text-sm font-normal text-start">Order Type</p>
            <select className="text-white border border-white p-1 px-4 outline-none bg-transparent rounded-md placeholder:text-black text-sm" value={orderType} onChange={(e) => setOrderType(e.target.value)}>
              {orderTypes.map(type => (
                <option className='bg-transparent text-black' key={type} value={type}>{type}</option>
              ))}
            </select>
          </div>

          {showAccountInfo && accountInfo && (
            <div className='flex flex-col text-white'>
              <h5 className='text-white font-bold'>Account Information</h5>
              {/* Placeholder for additional account information */}
              <p>Asset: <span className='text-green-500'>{accountInfo.asset}</span></p>
              <p>Wallet Balance:<span className='text-green-500'>{accountInfo.walletBalance}</span></p>
              <p>Unrealized Profit: <span className='text-green-500'>{accountInfo.unrealizedProfit}</span></p>
              <p>Margin Balance:<span className='text-green-500'>{accountInfo.marginBalance}</span> </p>
              <p>Position Initial Margin:<span className='text-green-500'>{accountInfo.positionInitialMargin}</span></p>
              <p>Open Order Initial Margin: <span className='text-green-500'>{accountInfo.openOrderInitialMargin}</span></p>
            </div>
          )}
        </div>
      </div>

    </div>
  );
}

export default withAuth(Trade);
