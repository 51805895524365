
const BlogManagement = () => {

    const handleCreateBlog = () => {
        window.location.href = "/blog-create"
    }
    return (
        <main className="w-full flex flex-col">
            <p className="text-white font-sans ">Got some thing on Your mind, Just jot it down and share it with your peers for a fair improvement in the affair!</p>
            <div className="w-full flex justify-end">
                <button className="flex gap-x-4 bg-[#ca217ebb] border-none outline-none rounded-xl text-white p-3" onClick={handleCreateBlog}>Create New Blog</button>

            </div>


        </main>
    )

}
export default BlogManagement;