import { Skeleton } from '@mantine/core';
import React from 'react'
import "../pages/on-board.css"

const TableSkeleton = ({ columns }) => {
  return (
    <table className="w-full text-sm rounded-lg text-major-text-style">
      <thead>
        <tr className="glass_morphism text-major-text-style py-10 rounded-md">
       
          {columns.map((column, i) => (
            <th
              key={i}
              className="p-3 font-semibold bg-transparent whitespace-nowrap"
            >
              <Skeleton height={30} />
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {[...Array(10)].map((_, index) => (
          <tr key={index} className="">
    
            {columns.map((column, i) => (
              <td key={i} className="p-2">
                <Skeleton height={30} />
              </td>
            ))}
            <Skeleton height={30} />
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default TableSkeleton;