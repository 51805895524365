export const faqData = [
    {
        number: 1,
        question: "How does Freedom Bot analyze the Bitcoin market?",
        answer: "Freedom Bot uses advanced algorithms and real-time data to analyze the Bitcoin market and other cryptocurrency pairs. It considers various factors such as market trends, volume, and historical data to provide accurate trade decisions like buy, sell, or hold."
    },
    {
        number: 2,
        question: "What are the unique features of Freedom Bot?",
        answer: "Freedom Bot offers a variety of unique features to help traders make informed decisions. It allows users to customize their trading strategy based on their risk tolerance, investment goals, and desired profit margins. Additionally, Freedom Bot provides real-time market updates and alerts, making it easy for users to stay informed and make informed trades."
    },
    {
        number: 3,
        question: "Which trading pairs are supported by Freedom Bot?",
        answer: "Freedom Bot supports multiple trading pairs including BTC/USD, ETH/BTC, and other popular pairs across various cryptocurrency exchanges. We continuously update our list of supported pairs to give traders more flexibility."
    },
    {
        number: 4,
        question: "How reliable are the trade decisions made by Freedom Bot?",
        answer: "Freedom Bot's decisions are based on sophisticated market analysis, but like any trading tool, it's important to understand that the crypto market is volatile. We recommend using Freedom Bot as a guide while incorporating your own research before making a final decision."

    },
    {
        number: 5,
        question: "Can I customize Freedom Bot's trading signals for my preferences?",
        answer: "Yes, Freedom Bot allows you to customize trading signals based on your risk tolerance and trading style. You can set preferences for aggressive, moderate, or conservative trading strategies."
    }
]